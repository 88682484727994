// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_card__fz\\+Uf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    width: 30rem; 
    height: -moz-fit-content; 
    height: fit-content; 
    padding: 1rem 2rem 2rem 2rem; 
    border-radius: 10px;
    box-shadow: 0 2px 10px rgb(0 0 0 / 8%); 
  }
  
  .Card_card__fz\\+Uf img {
    margin-top: 0;
    width: 100px; 
    height: 100px; 
    border-radius: 50%; 
    margin: 0 auto;
  }
  
  .Card_card__fz\\+Uf h2 {
    margin: 0;
    margin-top: 1rem;
    text-align: center;
  }
  
  .Card_card__fz\\+Uf h3 {
    margin: 0;
    margin-top: 0.5rem; 
    text-align: center;
    color: gray; 
  }
  
  .Card_card__fz\\+Uf p {
    margin: 0;
    margin-top: 0.5rem;
    text-align: center; 
  }
`, "",{"version":3,"sources":["webpack://./src/pages/LandingComponent/Card.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,YAAY;IACZ,wBAAmB;IAAnB,mBAAmB;IACnB,4BAA4B;IAC5B,mBAAmB;IACnB,sCAAsC;EACxC;;EAEA;IACE,aAAa;IACb,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,SAAS;IACT,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,SAAS;IACT,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,SAAS;IACT,kBAAkB;IAClB,kBAAkB;EACpB","sourcesContent":[".card {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    background-color: white;\n    width: 30rem; \n    height: fit-content; \n    padding: 1rem 2rem 2rem 2rem; \n    border-radius: 10px;\n    box-shadow: 0 2px 10px rgb(0 0 0 / 8%); \n  }\n  \n  .card img {\n    margin-top: 0;\n    width: 100px; \n    height: 100px; \n    border-radius: 50%; \n    margin: 0 auto;\n  }\n  \n  .card h2 {\n    margin: 0;\n    margin-top: 1rem;\n    text-align: center;\n  }\n  \n  .card h3 {\n    margin: 0;\n    margin-top: 0.5rem; \n    text-align: center;\n    color: gray; \n  }\n  \n  .card p {\n    margin: 0;\n    margin-top: 0.5rem;\n    text-align: center; \n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Card_card__fz+Uf`
};
export default ___CSS_LOADER_EXPORT___;
