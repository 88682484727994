import React from 'react'
import DashboardCards from './DashboardCards';
// import DashboardTable from '../components/DashboardTable';

const Home = () => {
  return (
    <>
      <DashboardCards />
     
    </>
    
  )
}

export default Home;